import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './Home'
import Dashboards from "./Dashboards"
import Somos from './Somos'
import Contacto from './Contacto'
import Clientes from './Clientes'
import Demos from './Demos'
import AI from './AI'


function Routes() {
    return (
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} /> 
                <Route exact path ="/Dashboards" component={Dashboards} />              
                <Route exact path ="/Somos" component={Somos} />              
                <Route exact path ="/Contacto" component={Contacto} />              
                <Route exact path ="/Clientes" component={Clientes} />           
                <Route exact path ="/Demos" component={Demos} />    
                <Route exact path ="/AI" component={AI} />       
            </Switch>
            </BrowserRouter>

    )
}

export default Routes
