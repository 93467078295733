import React from "react";
import { Row, Col, Container } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";

function AI() {
  return (
    <>
      <div className="banner" style={{ backgroundColor: "#2c3e50", color: "#fff" }}>
        <Header />
        <Container id="AI" className="py-5">
          <Row className="align-items-center">
            <Col md={12} className="text-center">
              <h1 className="display-5 mb-4">
                Grandes noticias: ¡Desarrollamos Inteligencia Artificial para nuestros clientes!
              </h1>
              <p className="lead">
                En Sistemify siempre trabajamos para ofrecerte las herramientas más avanzadas para optimizar y hacer crecer tu negocio. Nuestro nuevo módulo de Inteligencia Artificial está diseñado para convertir tus datos en decisiones inteligentes.
              </p>
            </Col>
            {/* <Col md={6} className="text-center">
              <img
                src={process.env.PUBLIC_URL + "/ai-banner.webp"}
                alt="Inteligencia Artificial"
                className="img-fluid rounded"
                style={{ maxWidth: "90%" }}
              />
            </Col> */}
          </Row>

          <Row className="mt-5">
            <Col>
              <h3 className="text-center mb-4">¿Qué podrás hacer con nuestro módulo de IA?</h3>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <strong>Detectar patrones clave:</strong> Identifica tendencias en pagos, ventas e inventarios para anticiparte al comportamiento del mercado.
                </li>
                <li className="mb-3">
                  <strong>Análisis predictivo:</strong> Genera proyecciones basadas en datos históricos para que siempre estés un paso adelante.
                </li>
                <li className="mb-3">
                  <strong>Reportes visuales en segundos:</strong> Obtén gráficos e imágenes de tendencias en tu industria, ideales para presentaciones y decisiones estratégicas.
                </li>
                <li className="mb-3">
                  <strong>Tips y consejos personalizados:</strong> Recibe recomendaciones empresariales adaptadas a tus necesidades específicas.
                </li>
                <li className="mb-3">
                  <strong>Simulación de escenarios:</strong> Prueba estrategias antes de implementarlas y elige la que mejor funcione para tu negocio.
                </li>
                <li className="mb-3">
                  <strong>Asesoría financiera sin errores humanos:</strong> Es como tener a tu lado un asesor financiero, pero mucho más preciso y confiable.
                </li>
                <li className="mb-3">
                  <strong>Identificación de oportunidades y riesgos:</strong> Encuentra áreas de oportunidad y detecta posibles problemas antes de que sucedan.
                </li>
                <li className="mb-3">
                  <strong>Automatización inteligente:</strong> Delega tareas repetitivas para centrarte en lo que realmente importa.
                </li>
              </ul>
            </Col>
          </Row>

          <Row className="text-center mt-5">
            <Col>
              <h4>Todo en tiempo real y a tu alcance</h4>
              <p className="mb-4">
                Nuestro módulo de IA funciona en la nube, lo que significa que tendrás acceso a insights clave de tu negocio en cualquier momento y lugar. Además, con nuestra plataforma intuitiva, no necesitas ser un experto en tecnología para aprovechar al máximo estas herramientas.
              </p>
              <p className="font-weight-bold">
                Con Sistemify y nuestro módulo de IA, simplificamos tu camino al éxito.
                ¿Listo para transformar tu negocio? <br /> Contáctanos para obtener más información y dar el siguiente gran paso.
              </p>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default AI;
